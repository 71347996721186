<template>
  <div>
    <vx-card
    style="font-size: 18px;font-family:almarai ; padding:15px ;"
      ref="filterCard"
      :title="$t('Filters')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">
        <!-- <template v-for="field in filterFields">
          <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4" :key="field.name">
            <label class="text-sm opacity-75">{{ $t(field.label) }}</label>
            <vs-input
              v-if="!field.isDropdown"
              v-model="search[field.model]"
              class="w-full"
              :name="field.name"
            />
          </div>
        </template> -->

        <div style="width: 200px;">
           <label style="font-size: 16px;font-family:almarai ;">{{ $t('VisitType') }}</label>
           <v-select
           class="mt-2 w-full"
           label= "name"
           name="Name"
           v-model="search.isRent"
           :reduce="(re) => re.Value"
           :options="visitTypes"
          />
          </div>

        <!-- Search Button -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <vs-button
          style="font-size: 16px;font-family:almarai;margin:35px ;"

            color="warning"
            @click="handleSearch"
            >{{ $t("Search") }}</vs-button
          >
        </div>
      </div>
    </vx-card>

    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        id="Scroll"
        pagination
        :max-items="itemsPerPage"
        search
        :data="NoticData"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >

          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  NoticData.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : NoticData.length
                }}
                of {{ NoticData.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">


          <!-- <vs-th sort-key="IdentityNumber">{{ $t("IdentityNumber") }}</vs-th>-->
          <vs-th style="font-size: 15px;font-family:almarai ; color: #007bff;" sort-key="UnitModel">{{ $t("Owner") }}</vs-th>
          <vs-th style="font-size: 15px;font-family:almarai ; color: #007bff;" sort-key="UnitModel">{{ $t("UnitModel") }}</vs-th>
          <vs-th style="font-size: 15px;font-family:almarai ; color: #007bff;" sort-key="EntranceTime">{{ $t("EntranceTime") }}</vs-th>
          <vs-th style="font-size: 15px;font-family:almarai ; color: #007bff;" sort-key="ExitTime">{{ $t("ExitTime") }}</vs-th>
          <vs-th style="font-size: 15px;font-family:almarai ; color: #007bff;" sort-key="VistorCount">{{ $t("VistorCount") }}</vs-th>
          <vs-th style="font-size: 15px;font-family:almarai ; color: #007bff;" sort-key="IsRent">{{ $t("VisitType") }}</vs-th>
          <vs-th style="font-size: 15px;font-family:almarai ; color: #007bff;" sort-key="StatusName">{{ $t("ServiceStatus") }}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: crimson;"> {{ $t("Action") }}</vs-th>

        </template>

        <template slot-scope="{ data }">
          <tbody>
            <tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              @click="AddOrEditData(tr)"
            >
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.ownerName }}
                </p>
              </vs-td>
              <!-- <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.nationalId }}
                </p>
              </vs-td>-->
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.unitModelName }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ formatDate(tr.entryDateTime) }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ formatDate(tr.ckeckOutDateTime) }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.totalWithVistiorCount }}
                </p>
              </vs-td>
              <vs-td>
                <span
                  v-if="tr.isRent === true"
                  :style="{
                    backgroundColor: 'DodgerBlue', // Green color for success status
                    padding: '8px 12px', // Adjust padding for better appearance
                    borderRadius: '20px', // Rounded corners
                    display: 'inline-block',
                    color: 'white',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                    transition: 'transform 0.2s ease-in-out', // Smooth transition
                    cursor: 'pointer', // Cursor pointer to indicate interactivity
                    position: 'relative' // Add position relative for pseudo-element
                  }"
                >
                  {{ $t("Rent") }}
                </span>

                <!-- Finished Status -->
                <span
                  v-else
                  :style="{
                    backgroundColor: 'SlateBlue', // Green color for success status
                    padding: '8px 12px', // Adjust padding for better appearance
                    borderRadius: '20px', // Rounded corners
                    display: 'inline-block',
                    color: 'white',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                    transition: 'transform 0.2s ease-in-out', // Smooth transition
                    cursor: 'pointer', // Cursor pointer to indicate interactivity
                    position: 'relative' // Add position relative for pseudo-element
                  }"
                >
                  <!-- Checkmark icon -->

                  {{ $t("Visit") }}
                </span>

              </vs-td>
              <vs-td>
                <span
                  v-if="tr.statusId == 1"
                  :style="{
                    backgroundColor: 'gray', // Green color for success status
                    padding: '8px 12px', // Adjust padding for better appearance
                    borderRadius: '20px', // Rounded corners
                    display: 'inline-block',
                    color: 'white',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                    transition: 'transform 0.2s ease-in-out', // Smooth transition
                    cursor: 'pointer', // Cursor pointer to indicate interactivity
                    position: 'relative' // Add position relative for pseudo-element
                  }"
                >
                  {{ $t("NewRequest") }}
                </span>

                <!-- Finished Status -->
                <span
                  v-else-if="tr.statusId == 2"
                  :style="{
                    backgroundColor: '#28C76F', // Green color for success status
                    padding: '8px 12px', // Adjust padding for better appearance
                    borderRadius: '20px', // Rounded corners
                    display: 'inline-block',
                    color: 'white',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                    transition: 'transform 0.2s ease-in-out', // Smooth transition
                    cursor: 'pointer', // Cursor pointer to indicate interactivity
                    position: 'relative' // Add position relative for pseudo-element
                  }"
                >
                  <!-- Checkmark icon -->

                  {{ $t("Accept") }}
                </span>

                <!-- Pending Status -->
                <span
                  v-else
                  :style="{
                    backgroundColor: 'Tomato', // Green color for success status
                    padding: '8px 12px', // Adjust padding for better appearance
                    borderRadius: '20px', // Rounded corners
                    display: 'inline-block',
                    color: 'white',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                    transition: 'transform 0.2s ease-in-out', // Smooth transition
                    cursor: 'pointer', // Cursor pointer to indicate interactivity
                    position: 'relative' // Add position relative for pseudo-element

                  }"
                >
                  {{ $t("Rejected") }}
                </span>

              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <router-link
                  :to="{
                    name: 'AddVisitOrRent',
                    params: { ID: tr.id },
                  }"
                >
                <vx-tooltip :text= "$t('Details')" position="top" delay=".3s">
                  <feather-icon
                    icon="EditIcon"
                    @click.stop="AddOrEditData(tr)"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  />
                </vx-tooltip>
                </router-link>

                <!-- <feather-icon
                  icon="TrashIcon"
                  svgClasses="w-10 h-5 hover:text-primary stroke-current"
                  @click.stop="openConfirm(tr)"
                /> -->
              </vs-td>
            </tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>


<script>
import { domain } from "@/gloabelConstant.js";
import moduleNoticeVisitAndRentModel from "@/store/NoticeVisitAndRent/moduleNoticeVisitAndRent.js";

export default {
  data() {
    return {
      statusId: 1,

      itemsPerPage: 10,
      search: {
        nationalId: "",
        phoneNumber: "",
        name: "",
        isRent: null,
        pageNumber: 0,
        pageSize: 20,
      },

      visitTypes: [
          {Value: false, name: " زيارة "},
          {Value: true, name: " ايجار "}
        ],


      filterFields: [
        { label: "Name", model: "name", name: "Name" },
        { label: "PhoneNumber", model: "phoneNumber", name: "PhoneNumber" },
        {label: "IdentityNumber",model: "nationalId",name: "IdentityNumber",},
      ],
     // NoticData: [],
      baseURL: domain,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    NoticData(){
      debugger;
        return this.$store.state.modulNoticeList.NoticeVisitAndRents;
    }
  },

  methods: {
    handleSearch() {
      debugger;
      this.$store
        .dispatch("modulNoticeList/SearchNoticeVisitAndRent", this.search)
        .then((res) => {
          debugger;
          this.NoticData = res.data.data;
        });
    },




    AddOrEditData(data) {
      if (data.id == undefined) {
        data.id = 0;
      }
      debugger;
      this.$router.push(`/VisitAndRentNotices/AddVisitOrRent/${data.id}`);
    },
    openConfirm(data) {
      debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },


    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });

      this.handleSearch(this.search);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },

    deleteData(data) {
      debugger;
      this.$store
        .dispatch("modulNoticeList/DeleteUnitDataObject", data)

        .catch((err) => {
          console.error(err);
        });
        this.$store
        .dispatch("modulNoticeList/SearchNoticeVisitAndRent", this.search)
    },
  },
  created() {
    if (!moduleNoticeVisitAndRentModel.isRegistered) {
      this.$store.registerModule(
        "modulNoticeList",
        moduleNoticeVisitAndRentModel
      );
      moduleNoticeVisitAndRentModel.isRegistered = true;
    }
    this.$store
        .dispatch("modulNoticeList/SearchNoticeVisitAndRent", this.search)

   // this.handleSearch(this.search);

  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}

.hover-red:hover {
  color: darkred;
}
.hover-green:hover {
  color: darkgreen; /* Change this to the desired hover color */
}

.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
